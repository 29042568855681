<template>
    <PPHeader :title="editMode ? 'Pickup locatie details' : 'Nieuwe pickup locatie'" />

    <form @submit.prevent="submit" class="my-5">
        <div class="mt-3 grid grid-cols-1 sm:grid-cols-2">
            <PPInput
                v-model="locationForm.name"
                name="name"
                label="Naam"
                :errors="v$.name.$errors.length"
            />
        </div>

        <div class="mt-3 grid grid-cols-1 sm:grid-cols-6 gap-y-3 sm:gap-x-3">
            <PPInput
                class="col-span-2"
                v-model="locationForm.address.street"
                name="street"
                label="Straatnaam"
                :errors="v$.address.street.$errors.length"
            />

            <PPInput
                v-model="locationForm.address.houseNumber"
                name="houseNumber"
                label="Huisnummer"
                :errors="v$.address.houseNumber.$errors.length"
            />
        </div>

        <div class="mt-3 grid grid-cols-1 sm:grid-cols-6 gap-y-3 sm:gap-x-3">
            <PPInput
                class="col-span-2"
                v-model="locationForm.address.city"
                name="city"
                label="Plaats"
                :errors="v$.address.city.$errors.length"
            />

            <PPInput
                v-model="locationForm.address.zipCode"
                name="zipCode"
                label="Postcode"
                :errors="v$.address.zipCode.$errors.length"
            />
        </div>

        <div class="mt-3 grid grid-cols-1 sm:grid-cols-2">
            <PPSelect
                name="country"
                :options="countryOptions"
                v-model="locationForm.address.country"
                label="Land"
                :errors="v$.address.country.$errors.length"
            />
        </div>

        <div class="mt-3 grid grid-cols-1 sm:grid-cols-2">
            <PPInput
                v-model="locationForm.website"
                name="website"
                label="Website"
                :errors="v$.website.$errors.length"
            />
        </div>

        <div class="mt-3 grid grid-cols-1 sm:grid-cols-2">
            <PPInput
                v-model="locationForm.price"
                name="price"
                label="Prijs voor ophalen"
                type="number"
                :errors="v$.price.$errors.length"
            />
        </div>

        <div class="mt-3 grid grid-cols-1 sm:grid-cols-2">
            <PPSelect
                :options="taxCategories"
                v-model="locationForm.taxCategory"
                name="tax_category"
                label="Belastingtarief"
                :errors="v$.taxCategory.$errors.length"
            />
        </div>

        <div class="mt-3 grid grid-cols-1 sm:grid-cols-2">
            <PPToggle
                v-model="locationForm.supportsTimeslots"
                name="supports_timeslots"
                label="Timeslots"
            />
        </div>

        <div class="mt-3 grid grid-cols-1 sm:grid-cols-2">
            <PPToggle
                v-model="locationForm.allowCat2"
                name="allow_cat_2"
                label="Cat 2 verkoop"
            />
        </div>

        <hr class="my-5" />

        <div class="flex gap-3">
            <PPButton @click="addTimeslot">Nieuw timeslot +</PPButton>
        </div>

        <div class="border-t border-gray-100 mt-3 w-1/2" v-if="timeslots.length">
            <dl class="divide-y divide-gray-100">
                <PPTimeslot
                    v-for="(timeslot, index) in timeslots"
                    :timeslot="timeslot"
                    :key="timeslot.id ?? timeslot.date"
                    @removeTimeslot="removeTimeslot(index)"
                />
            </dl>
        </div>

        <hr class="my-5" />

        <PPAlert class="mb-3" :error="error" />

        <PPButton type="submit">Opslaan</PPButton>
        <PPButton class="ml-5" v-if="editMode" color="danger" @click="deleteLocation">
            Verwijder
        </PPButton>
    </form>
</template>

<script setup lang="ts">
    import type { Option } from '@/components/PPSelect.vue';
    import type { PickupLocation } from '@/types/api/response/pickupLocation';
    import type { Address } from '@/types/api/response/address';
    import type { AxiosResponse } from 'axios';

    import PPHeader from '@/components/PPHeader.vue';
    import PPInput from '@/components/PPInput.vue';
    import PPButton from '@/components/PPButton.vue';
    import PPSelect from '@/components/PPSelect.vue';
    import PPAlert from '@/components/PPAlert.vue';
    import PPToggle from '@/components/PPToggle.vue';
    import PPTimeslot from '@/components/PPTimeslot.vue';

    import { inject, onMounted, ref } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useFlashMessage } from '@/stores/flash-message';

    import { FlashMessageType } from '@/types/flash-message';

    // Form and validation
    import { useVuelidate } from '@vuelidate/core';
    import { required, url, minValue } from '@vuelidate/validators';

    import countries from '@/utils/countries';

    const axios = inject('axios');

    const route = useRoute();
    const router = useRouter();
    const flashMessage = useFlashMessage();
    const editMode = ref(false);
    const location = ref({});
    const timeslots = ref([]);
    const taxCategories = ref([]);
    const countryOptions: Option[] = countries.map((c) => ({ name: c.name, value: c.code }));

    type LocationForm = {
        id?: string;
        name: string;
        address: Address;
        supportsTimeslots?: boolean;
        allowCat2: boolean;
        geo?: Array<string>;
        website?: string;
        price?: number;
        taxCategory?: string;
    };

    onMounted(async () => {
        if (route.params.id) {
            await getLocation();
            editMode.value = true;
        }

        await getTaxCategories();
    });

    const getLocation = async () => {
        try {
            const response: AxiosResponse<PickupLocation> = await axios.get(
                `/admin/shipping/pickup-location/${route.params.id}`
            );

            location.value = response.data;
            locationForm.value = Object.assign({}, locationForm.value, location.value);
            timeslots.value = locationForm.value.timeslots;
        } catch (e) {
            console.error('Something went wrong!', e);
        }
    };

    const deleteLocation = async () => {
        try {
            const response = await axios.delete(
                `/admin/shipping/pickup-location/${route.params.id}`
            );

            if (response.status === 204) {
                flashMessage.add({
                    type: FlashMessageType.Success,
                    message: 'Successfully deleted pickup location!'
                });
            }

            await router.push({ name: 'pickup-locations' });
        } catch (e) {
            error.value = e.data.detail ?? 'Something went wrong!';
            console.error('Something went wrong!', e);
        }
    };

    // Tax categories
    const getTaxCategories = async () => {
        try {
            const response = await axios.get('/admin/tax/categories');
            taxCategories.value = response.data.data.map((c) => ({ name: c.name, value: c.id }));
        } catch (e) {
            console.error('Something went wrong!', e);
        }
    };

    // Timeslots
    const addTimeslot = (timeslot) => {
        timeslots.value.push({ date: '', startTime: '', endTime: '', slots: 0 });
    };

    const removeTimeslot = async (index) => {
        timeslots.value.splice(index, 1);
    };

    const saveTimeslot = async (timeslot) => {
        let url = `/admin/shipping/pickup-location/${route.params.id}/timeslot`;

        if (timeslot.id) {
            url += `/${timeslot.id}`;
        }

        const method = timeslot.id ? 'PUT' : 'POST';
        const data = timeslot;

        try {
            await axios({ method, url, data });
        } catch (e) {
            console.error('Something went wrong!', e);
        }
    };

    const locationForm = ref<LocationForm>({
        id: undefined,
        name: '',
        address: {
            street: '',
            houseNumber: '',
            zipCode: '',
            city: '',
            country: 'NL'
        },
        supportsTimeslots: false,
        allowCat2: false,
        geo: [],
        website: undefined,
        price: 0,
        taxCategory: undefined
    });

    const rules = {
        name: { required },
        address: {
            street: { required },
            houseNumber: { required },
            zipCode: { required },
            city: { required },
            country: { required }
        },
        website: { url },
        price: { minValue: minValue(0) },
        taxCategory: {}
    };

    const v$ = useVuelidate(rules, locationForm);
    const error = ref('');

    // Form submission
    const submit = async () => {
        const result = await v$.value.$validate();

        if (!result) {
            return;
        }

        error.value = '';

        const url = editMode.value
            ? `/admin/shipping/pickup-location/${route.params.id}`
            : '/admin/shipping/pickup-location';
        const method = editMode.value ? 'PATCH' : 'POST';
        const data: any = locationForm.value;

        // Remove the geo array if there are less than 2 elements in the array
        if (data.geo.length < 2) {
            delete data.geo;
        }

        try {
            const response = await axios({ method, url, data });

            if (response.status === 200 || response.status === 201) {
                const action = editMode.value ? 'updated' : 'created';

                for (let timeslot of timeslots.value) {
                    await saveTimeslot(timeslot);
                }

                flashMessage.add({
                    type: FlashMessageType.Success,
                    message: `Successfully ${action} pickup location!`
                });
            }

            await router.push({ name: 'pickup-locations' });
        } catch (e) {
            error.value = e.data.detail;
            console.error('Something went wrong while updating or creating pickup location!', e);
        }
    };
</script>
