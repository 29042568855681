<template>
    <PPHeader title="Pickup locaties" href="new-pickup-location" />

    <div class="mt-3">
        <PPDataTable
            :headers="headers"
            resourceUrl="/admin/shipping/pickup-locations"
            :rowClicked="(row) => $router.push({ name: 'edit-pickup-location', params: { id: row.id } })"
            :filter="true"
        />
    </div>
</template>

<script setup lang="ts">
    import PPDataTable from '@/components/PPDataTable.vue';
    import PPHeader from '@/components/PPHeader.vue';

    const headers = [
        // {key: 'id', title: 'ID'},
        { key: 'name', title: 'Naam' }
    ];
</script>
