<template>
    <div class="flex items-end gap-x-3 py-3">
        <dt class="text-sm font-medium leading-6 text-gray-900 flex-auto w-32">
            <PPInput
                name="date"
                v-model="timeslot.date"
                type="date"
                label="Datum"
                :errors="v$.date.$errors.length"
            />
        </dt>
        <dd class="flex-1">
            <PPInput
                name="start_time"
                v-model="timeslot.startTime"
                type="time"
                label="Starttijd"
                :errors="v$.startTime.$errors.length"
            />
        </dd>
        <dd class="flex-1">
            <PPInput
                name="end_time"
                v-model="timeslot.endTime"
                type="time"
                label="Eindtijd"
                :errors="v$.endTime.$errors.length"
            />
        </dd>
        <dd class="flex-1">
            <PPInput
                name="slots"
                v-model="timeslot.slots"
                type="number"
                label="Slots"
                :errors="v$.slots.$errors.length"
            />
        </dd>
        <dd class="grow-0">
            <PPButton color="danger" size="block" @click="removeTimeslot(timeslot)"> x</PPButton>
        </dd>
    </div>
</template>
<script setup lang="ts">
    import PPButton from '@/components/PPButton.vue';
    import PPInput from '@/components/PPInput.vue';

    import { useRoute } from 'vue-router';
    import { inject } from 'vue';

    import { useVuelidate } from '@vuelidate/core';
    import { required } from '@vuelidate/validators';

    const route = useRoute();
    const axios = inject('axios');

    const props = defineProps<{
        timeslot: {
            date: string;
            startTime: string;
            endTime: string;
            slots: number;
        };
    }>();

    const emits = defineEmits(['removeTimeslot']);

    const removeTimeslot = async (timeslot) => {
        if (timeslot.id) {
            try {
                await axios.delete(
                    `/admin/shipping/pickup-location/${route.params.id}/timeslot/${timeslot.id}`
                );
            } catch (e) {
                console.error('Something went wrong', e);
            }
        }

        emits('removeTimeslot');
    };

    const rules = {
        date: { required },
        startTime: { required },
        endTime: { required },
        slots: {}
    };

    const v$ = useVuelidate(rules, props.timeslot);
</script>
